import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';
import { LoadingScreen } from 'src/components/loading-screen';
import PermissionGuard from 'src/auth/guard/permission-guard';


// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/site-administration/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/site-administration/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/site-administration/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/site-administration/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/site-administration/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/site-administration/user/edit'));
// Roll
const RoleListPage = lazy(() => import('src/pages/dashboard/site-administration/roles/list'));
const RoleCreatePage = lazy(() => import('src/pages/dashboard/site-administration/roles/create'));
const RoleEditPage = lazy(() => import('src/pages/dashboard/site-administration/roles/edit'));
const RoleDetailsPage = lazy(() => import('src/pages/dashboard/site-administration/roles/details'));
// Permissions
const PermissionsListPage = lazy(() =>
    import('src/pages/dashboard/site-administration/permissions/list')
);
const PermissionsCreatePage = lazy(() =>
    import('src/pages/dashboard/site-administration/permissions/create')
);
const PermissionsEditPage = lazy(() =>
    import('src/pages/dashboard/site-administration/permissions/edit')
);
const PermissionsDetailsPage = lazy(() =>
    import('src/pages/dashboard/site-administration/permissions/details')
);
// Organisation
const OrganisationEditPage = lazy(() =>
    import('src/pages/dashboard/uem-setting/organisation/edit')
);
const OrganisationDetailsPage = lazy(() =>
    import('src/pages/dashboard/uem-setting/organisation/details')
);
// Enterprises
const EnterprisesListPage = lazy(() => import('src/pages/dashboard/uem-setting/enterprises/list'));
const EnterprisesCreatePage = lazy(() =>
    import('src/pages/dashboard/uem-setting/enterprises/create')
);

const EnterprisesEditPage = lazy(() => import('src/pages/dashboard/uem-setting/enterprises/edit'));
const EnterprisesDetailsPage = lazy(() =>
    import('src/pages/dashboard/uem-setting/enterprises/details')
);
// OrganisationHierarchy
const OrganisationHierarchyListPage = lazy(() =>
    import('src/pages/dashboard/site-administration/organisationhierarchy/list')
);
const OrganisationHierarchyCreatePage = lazy(() =>
    import('src/pages/dashboard/site-administration/organisationhierarchy/create')
);
const OrganisationHierarchyEditPage = lazy(() =>
    import('src/pages/dashboard/site-administration/organisationhierarchy/edit')
);
const OrganisationHierarchyDetailsPage = lazy(() =>
    import('src/pages/dashboard/site-administration/organisationhierarchy/details')
);
// setting
const SettingEditPage = lazy(() => import('src/pages/dashboard/uem-setting/setting/edit'));
const SettingDetailsPage = lazy(() => import('src/pages/dashboard/uem-setting/setting/details'));
// termsofusedoc
const TermsOfUseDocListPage = lazy(() =>
    import('src/pages/dashboard/uem-setting/terms-of-use-doc/list')
);
const TermsOfUseDocCreatePage = lazy(() =>
    import('src/pages/dashboard/uem-setting/terms-of-use-doc/create')
);
const TermsOfUseDocEditPage = lazy(() =>
    import('src/pages/dashboard/uem-setting/terms-of-use-doc/edit')
);
const TermsOfUseDocDetailsPage = lazy(() =>
    import('src/pages/dashboard/uem-setting/terms-of-use-doc/details')
);
// app repository
const AppRepositoryListPage = lazy(() =>
    import('src/pages/dashboard/application-management/app-repository/list')
);
const AppRepositoryDetailsPage = lazy(() =>
    import('src/pages/dashboard/application-management/app-repository/detail')
);
const AppRepositoryCreatePage = lazy(() =>
    import('src/pages/dashboard/application-management/app-repository/create')
);
const AppRepositoryCreatePagetest = lazy(() =>
    import('src/pages/dashboard/application-management/app-repository/create-test')
);

const Appstorepublicapps = lazy(() =>
    import('src/pages/dashboard/application-management/app-repository/app-google-public-apps')
);
const Appstoreprivateapps = lazy(() =>
    import('src/pages/dashboard/application-management/app-repository/app-google-private-apps')
);
const Appstorewebapps = lazy(() =>
    import('src/pages/dashboard/application-management/app-repository/app-google-web-apps')
);

const OrganizeApps = lazy(() =>
    import('src/pages/dashboard/application-management/app-repository/organize-apps')
);

const OrgPrivateApps = lazy(() =>
    import('src/pages/dashboard/application-management/app-repository/app-org-private-store')
);
const WebApps = lazy(() =>
    import('src/pages/dashboard/application-management/app-repository/web-apps')
);

const DistributeApps = lazy(() =>
    import('src/pages/dashboard/application-management/app-repository/app-distribute')
);
const AppList = lazy(() =>
    import('src/pages/dashboard/application-management/app-repository/app-list')
);
const AppSecurity = lazy(() =>
    import('src/pages/dashboard/application-management/app-repository/app-security')
);
const AppRepositoryEditPage = lazy(() =>
    import('src/pages/dashboard/application-management/app-repository/edit')
);
const AppRepositoryDistributePage = lazy(() =>
    import('src/pages/dashboard/application-management/app-repository/distribute')
);
const AppRepositorytest = lazy(() =>
    import('src/pages/dashboard/application-management/app-repository/test')
);

// CONTENT MANAGER
const ContentManager = lazy(() => import('src/pages/dashboard/content-manager/file-manager'));

// const AppRepositoryDistributePage = lazy(() =>import('src/pages/dashboard/application-management/app-repository/distribute'));
// content
const ContentDetailsPage = lazy(() =>
    import('src/pages/dashboard/content-management/content/details')
);
const ContentListPage = lazy(() => import('src/pages/dashboard/content-management/content/list'));
const ContentCreatePage = lazy(() =>
    import('src/pages/dashboard/content-management/content/create')
);
const ContentEditPage = lazy(() => import('src/pages/dashboard/content-management/content/edit'));
// app update policy
const AppUpdatePolicyDetailsPage = lazy(() =>
    import('src/pages/dashboard/application-management/app-update-policy/details')
);
const AppUpdatePolicyListPage = lazy(() =>
    import('src/pages/dashboard/application-management/app-update-policy/list')
);
const AppUpdatePolicyEditPage = lazy(() =>
    import('src/pages/dashboard/application-management/app-update-policy/edit')
);
const AppUpdatePolicyCreate = lazy(() =>
    import('src/pages/dashboard/application-management/app-update-policy/create')
);
// certificates
const CertificatesDetailsPage = lazy(() =>
    import('src/pages/dashboard/device-management/certificates/details')
);
const CertificatesListPage = lazy(() =>
    import('src/pages/dashboard/device-management/certificates/list')
);
const CertificatesCreatePage = lazy(() =>
    import('src/pages/dashboard/device-management/certificates/create')
);
const CertificatesEditPage = lazy(() =>
    import('src/pages/dashboard/device-management/certificates/edit')
);
const CertificatesCAServerPage = lazy(() =>
    import('src/pages/dashboard/device-management/certificates/ca-server')
);
const CertificatesTemplatesPage = lazy(() =>
    import('src/pages/dashboard/device-management/certificates/templates')
);
// device-alerts
const DeviceAlertsEditPage = lazy(() =>
    import('src/pages/dashboard/device-management/device-alerts/edit')
);
const DeviceAlertsDetailsPage = lazy(() =>
    import('src/pages/dashboard/device-management/device-alerts/details')
);
const DeviceAlertsCreatePage = lazy(() =>
    import('src/pages/dashboard/device-management/device-alerts/create')
);

// devices
const DevicesDetailsCreatePage = lazy(() =>
    import('src/pages/dashboard/device-management/devices/create')
);
const DevicesDetailsNewCreatePage = lazy(() =>
    import('src/pages/dashboard/device-management/devices/newcreate')
);
const DevicesDetailsPage = lazy(() =>
    import('src/pages/dashboard/device-management/devices/details')
);
const DevicesDetailsPage1 = lazy(() =>
    import('src/pages/dashboard/device-management/devices/details1')
);
const DevicesEditPage = lazy(() => import('src/pages/dashboard/device-management/devices/edit'));
const DevicesListPage = lazy(() => import('src/pages/dashboard/device-management/devices/list'));
const DevicesBulkEditPage = lazy(() =>
    import('src/pages/dashboard/device-management/devices/bulk-edit')
);
// os-updates
const OSUpdatesDetailsPage = lazy(() =>
    import('src/pages/dashboard/device-management/os-updates/details')
);
const OSUpdatesListPage = lazy(() =>
    import('src/pages/dashboard/device-management/os-updates/list')
);
const OSUpdatesCreatePage = lazy(() =>
    import('src/pages/dashboard/device-management/os-updates/create')
);
const OSUpdatesEditPage = lazy(() =>
    import('src/pages/dashboard/device-management/os-updates/edit')
);

// Policies
const PoliciesListPage = lazy(() => import('src/pages/dashboard/device-security/policies/list'));
const PoliciesCreatePage = lazy(() =>
    import('src/pages/dashboard/device-security/policies/create')
);

// profiles
const ProfilesDetailPage = lazy(() =>
    import('src/pages/dashboard/device-security/profiles/details')
);

const ProfilesListPage = lazy(() => import('src/pages/dashboard/device-security/profiles/list'));
const ProfilesCreatePage = lazy(() =>
    import('src/pages/dashboard/device-security/profiles/create')
);

const ProfilesNewCreatePage = lazy(() =>
    import('src/pages/dashboard/device-security/profiles/newcreate')
);

const ProfilesEditPage = lazy(() => import('src/pages/dashboard/device-security/profiles/edit'));
const ProfilesWorkPage = lazy(() =>
    import('src/pages/dashboard/device-security/profiles/workprofile')
);

const GroupsDetailsPage = lazy(() =>
    import('src/pages/dashboard/device-management/groups/details')
);
const GroupsListPage = lazy(() => import('src/pages/dashboard/device-management/groups/list'));
const GroupsCreatePage = lazy(() => import('src/pages/dashboard/device-management/groups/create'));
const GroupsEditPage = lazy(() => import('src/pages/dashboard/device-management/groups/edit'));
const SelfEnrollmentDetailsPage = lazy(() =>
    import('src/pages/dashboard/enrollments/self-enrollment/details')
);
const UsersDetailsPage = lazy(() => import('src/pages/dashboard/device-management/users/details'));
const UsersListPage = lazy(() => import('src/pages/dashboard/device-management/users/list'));
const UsersCreatePage = lazy(() => import('src/pages/dashboard/device-management/users/create'));
const UsersEditPage = lazy(() => import('src/pages/dashboard/device-management/users/edit'));
const DeviceUserCardsPage = lazy(() => import('src/pages/dashboard/device-management/users/cards'));

const UsersBulkEditPage = lazy(() =>
    import('src/pages/dashboard/device-management/users/bulk-edit')
);
const SelfEnrollmentEditPage = lazy(() =>
    import('src/pages/dashboard/enrollments/self-enrollment/edit')
);
const DirectoryServicesDetailsPage = lazy(() =>
    import('src/pages/dashboard/enrollments/directory-services/details')
);
const DirectoryServicesEditPage = lazy(() =>
    import('src/pages/dashboard/enrollments/directory-services/edit')
);
const AndroidDevicesEMMTokenEnrollmentPage = lazy(() =>
    import('src/pages/dashboard/enrollments/android-devices/emm-token-enrollment')
);
const AndroidDevicesKnoxMobileEnrollmentPage = lazy(() =>
    import('src/pages/dashboard/enrollments/android-devices/knox-mobile-enrollment')
);
const AndroidDevicesNFCEnrollmentPage = lazy(() =>
    import('src/pages/dashboard/enrollments/android-devices/nfc-enrollment')
);
const AndroidDevicesZeroTouchEnrollmentPage = lazy(() =>
    import('src/pages/dashboard/enrollments/android-devices/zero-touch-enrollment')
);
const DevicesEnrollDevicesPage = lazy(() =>
    import('src/pages/dashboard/device-management/device/enroll-devices')
);
const EnrollDeviceAndroidPage = lazy(() =>
    import('src/pages/dashboard/device-management/device/enroll-device-android')
);
const EnrollDeviceAndroidPage2 = lazy(() =>
    import('src/pages/dashboard/device-management/device/enroll-device-android2')
);
const AppleConfigCreatePage = lazy(()=>
    import('src/pages/dashboard/device-management/apple/apple-config-create')
)
const EnrollDeviceSteps = lazy(() =>
    import('src/pages/dashboard/device-management/device/enroll-device-steps')
);
const EnrollWorkProfileDeviceSteps = lazy(() =>
    import('src/pages/dashboard/device-management/device/enroll-work-profile')
);
const EnrolledDevicesDetailsPage = lazy(() =>
    import('src/pages/dashboard/device-management/device/details')
);
const TokenList = lazy(() => import('src/pages/dashboard/device-management/device/tokenList'));
const TokensTab = lazy(() => import('src/pages/dashboard/device-management/device/tokensTab'));
const CruxUEMAppQrCodeLinkToAppPage = lazy(() =>
    import('src/pages/dashboard/enrollments/crux-uem-app/qr-code-link-to-app')
);
const EnterprisesAndGroupsPage = lazy(() =>
    import('src/pages/dashboard/device-management/enterprises-and-groups/action-on-groups')
);
const ScheduleDeviceScanCreateSchedulePage = lazy(() =>
    import('src/pages/dashboard/device-management/schedule-device-scan/create-schedule')
);
const ScheduleDeviceScanNowPage = lazy(() =>
    import('src/pages/dashboard/device-management/schedule-device-scan/scan-now')
);

// geo fencing

const GeoFencingCreate = lazy(() =>
    import('src/pages/dashboard/location-management/new-geo-fence/create')
);

const GeoFencingCards = lazy(() =>
    import('src/pages/dashboard/location-management/new-geo-fence/cards')
);

const GeoFenceLogs = lazy(() =>
    import('src/pages/dashboard/location-management/new-geo-fence/logs')
);

const GeoFenceApply = lazy(() =>
    import('src/pages/dashboard/location-management/new-geo-fence/apply')
);
// old geo fencing
const GeoFencePolicyDetailsPage = lazy(() =>
    import('src/pages/dashboard/location-management/geo-fence-policy/details')
);
const GeoFencePolicyListPage = lazy(() =>
    import('src/pages/dashboard/location-management/geo-fence-policy/list')
);
const GeoFencePolicyCreatePage = lazy(() =>
    import('src/pages/dashboard/location-management/geo-fence-policy/create')
);
const GeoFencePolicyEditPage = lazy(() =>
    import('src/pages/dashboard/location-management/geo-fence-policy/edit')
);
const GeoFenceMaps = lazy(() =>
    import('src/pages/dashboard/location-management/geo-fence-policy/Maps')
);
const GeoFenceRepositoryDetailsPage = lazy(() =>
    import('src/pages/dashboard/location-management/geo-fence-repository/details')
);
const GeoFenceRepositoryListPage = lazy(() =>
    import('src/pages/dashboard/location-management/geo-fence-repository/list')
);
const GeoFenceRepositoryCreatePage = lazy(() =>
    import('src/pages/dashboard/location-management/geo-fence-repository/create')
);
const GeoFenceRepositoryEditPage = lazy(() =>
    import('src/pages/dashboard/location-management/geo-fence-repository/edit')
);

// WindowsEnrollment

// tracking

const LocationHistory = lazy(() =>
    import('src/pages/dashboard/location-management/maps/location-history')
);
const LiveTracking = lazy(() =>
    import('src/pages/dashboard/location-management/maps/live-tracking')
);
const RouteMapsBreadCrumbs = lazy(() =>
    import('src/pages/dashboard/location-management/maps/route-maps')
);

// remote control

const RemoteControl = lazy(() => import('src/pages/dashboard/remote-control/control'));

const ReportsPage = lazy(() => import('src/pages/dashboard/reports/report'));
const ToolsRemoteControlPage = lazy(() => import('src/pages/dashboard/tools/remote-control'));
const ToolsNotificationPage = lazy(() => import('src/pages/dashboard/tools/notification'));

// Windows enrollment

const AutoEnrollmentPage= lazy(()=> import('src/pages/dashboard/windows-enrollment/auto-enrollment'));

// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

const AppleAPN = lazy(() => import(  'src/pages/dashboard/device-management/apple/apn-certificate'));
const AppleEnrollmentABM = lazy(()=> import('src/pages/dashboard/device-management/apple/AppleEnrollmentABM'));
const AddServer = lazy(()=> import('src/pages/dashboard/device-management/apple/AddServer'));


// ----------------------------------------------------------------------

export const dashboardRoutes = [
    {
        path: 'dashboard',
        element: (
            <AuthGuard>
                <PermissionGuard>
                    <DashboardLayout>
                        <Suspense fallback={<LoadingScreen />}>
                            <Outlet />
                        </Suspense>
                    </DashboardLayout>
                </PermissionGuard>
            </AuthGuard>
        ),
        children: [
            { element: <IndexPage />, index: true },
            { path: 'ecommerce', element: <OverviewEcommercePage /> },
            { path: 'analytics', element: <OverviewAnalyticsPage /> },
            { path: 'banking', element: <OverviewBankingPage /> },
            { path: 'booking', element: <OverviewBookingPage /> },
            { path: 'file', element: <OverviewFilePage /> },
            {
                path: 'site-administration/user',
                children: [
                    { element: <UserProfilePage />, index: true },
                    { path: ':id/profile', element: <UserProfilePage /> },
                    { path: 'cards', element: <UserCardsPage /> },
                    { path: 'list', element: <UserListPage /> },
                    { path: 'new', element: <UserCreatePage /> },
                    { path: 'edit', element: <UserEditPage /> },
                    { path: 'account', element: <UserAccountPage /> },
                ],
            },
            {
                path: 'site-administration/roles',
                children: [
                    { element: <RoleDetailsPage />, index: true },
                    { path: 'list', element: <RoleListPage /> },
                    { path: 'create', element: <RoleCreatePage /> },
                    { path: 'edit', element: <RoleEditPage /> },
                ],
            },
            {
                path: 'site-administration/permissions',
                children: [
                    { element: <PermissionsDetailsPage />, index: true },
                    { path: 'list', element: <PermissionsListPage /> },
                    { path: 'create', element: <PermissionsCreatePage /> },
                    { path: 'edit', element: <PermissionsEditPage /> },
                ],
            },
            {
                path: 'uem-setting/organisation',
                children: [
                    { element: <OrganisationDetailsPage />, index: true },
                    { path: 'edit', element: <OrganisationEditPage /> },
                ],
            },
            {
                path: 'uem-setting/enterprises',
                children: [
                    { element: <EnterprisesDetailsPage />, index: true },
                    { path: 'list', element: <EnterprisesListPage /> },
                    { path: 'details/:id', element: <EnterprisesDetailsPage /> },
                    { path: 'create', element: <EnterprisesCreatePage /> },
                    { path: 'create/:id', element: <EnterprisesCreatePage /> },
                    { path: 'edit/:id', element: <EnterprisesEditPage /> },
                ],
            },
            {
                path: 'uem-setting/organisation-hierarchy',
                children: [
                    { element: <OrganisationHierarchyDetailsPage />, index: true },
                    { path: 'list', element: <OrganisationHierarchyListPage /> },
                    { path: 'create', element: <OrganisationHierarchyCreatePage /> },
                    { path: 'edit', element: <OrganisationHierarchyEditPage /> },
                ],
            },

            {
                path: 'uem-setting/setting',
                children: [
                    { element: <SettingDetailsPage />, index: true },
                    { path: 'edit', element: <SettingEditPage /> },
                ],
            },

            {
                path: 'uem-setting/terms-of-use-doc',
                children: [
                    { element: <TermsOfUseDocDetailsPage />, index: true },
                    { path: 'list', element: <TermsOfUseDocListPage /> },
                    { path: 'create', element: <TermsOfUseDocCreatePage /> },
                    { path: 'edit', element: <TermsOfUseDocEditPage /> },
                ],
            },

            {
                path: 'device-management/groups',
                children: [
                    { path: ':group_id/details', element: <GroupsDetailsPage /> },
                    { path: 'list', element: <GroupsListPage /> },
                    { path: 'create', element: <GroupsCreatePage /> },
                    { path: 'edit', element: <GroupsEditPage /> },
                ],
            },
            {
                path: 'device-management/users',
                children: [
                    { element: <UsersDetailsPage />, index: true },
                    { path: 'list', element: <UsersListPage /> },
                    { path: ':id/details', element: <UsersDetailsPage /> },
                    { path: 'create', element: <UsersCreatePage /> },
                    { path: 'cards', element: <DeviceUserCardsPage /> },
                    { path: ':id/edit', element: <UsersEditPage /> },
                    { path: 'bulk-edit', element: <UsersBulkEditPage /> },
                ],
            },
            {
                path: 'enrollments/self-enrollment',
                children: [
                    { element: <SelfEnrollmentDetailsPage />, index: true },
                    { path: 'edit', element: <SelfEnrollmentEditPage /> },
                ],
            },
            {
                path: 'enrollments/directory-services',
                children: [
                    { element: <DirectoryServicesDetailsPage />, index: true },
                    { path: 'edit', element: <DirectoryServicesEditPage /> },
                ],
            },
            {
                path: 'enrollments/android-devices',
                children: [
                    { element: <AndroidDevicesEMMTokenEnrollmentPage />, index: true },
                    {
                        path: 'knox-mobile-enrollment',
                        element: <AndroidDevicesKnoxMobileEnrollmentPage />,
                    },
                    { path: 'nfc-enrollment', element: <AndroidDevicesNFCEnrollmentPage /> },
                    {
                        path: 'zero-touch-enrollment',
                        element: <AndroidDevicesZeroTouchEnrollmentPage />,
                    },
                ],
            },
            {
                path: 'device-management/device',
                children: [
                    { element: <DevicesEnrollDevicesPage />, index: true },
                    { path: 'enroll-device-android', element: <EnrollDeviceAndroidPage /> },
                    { path: 'enroll-device-android2', element: <EnrollDeviceAndroidPage2 /> },
                    { path: 'enroll-device-steps', element: <EnrollDeviceSteps /> },
                    { path: 'enroll-work-profile', element: <EnrollWorkProfileDeviceSteps /> },
                    { path: ':id/enroll-device-details', element: <EnrolledDevicesDetailsPage /> },
                    { path: 'TokenList', element: <TokenList /> },
                    { path: 'TokensTab', element: <TokensTab /> },
                ],
            },
            {
                path: 'device-management/apple',
                children: [
                    { element: <AppleEnrollmentABM />, index: true },
                    { path: 'apn-certificate', element: <AppleAPN /> },
                    { path: 'AppleEnrollmentABM', element: <AppleEnrollmentABM /> },
                    { path: 'AddServer', element: <AddServer /> },
                    { path: 'apple-config-create', element: <AppleConfigCreatePage /> },
                ],
            },

            {
                path: 'enrollments/crux-uem-app',
                children: [{ element: <CruxUEMAppQrCodeLinkToAppPage />, index: true }],
            },

            {
                path: 'device-management/certificates',
                children: [
                    { element: <CertificatesDetailsPage />, index: true },
                    { path: 'list', element: <CertificatesListPage /> },
                    { path: 'create', element: <CertificatesCreatePage /> },
                    { path: 'edit', element: <CertificatesEditPage /> },
                    { path: 'ca-server', element: <CertificatesCAServerPage /> },
                    { path: 'templates', element: <CertificatesTemplatesPage /> },
                ],
            },

            {
                path: 'device-management/device-alerts',
                children: [
                    { element: <DeviceAlertsDetailsPage />, index: true },
                    { path: 'edit', element: <DeviceAlertsEditPage /> },
                    { path: 'create', element: <DeviceAlertsCreatePage /> },
                ],
            },

            {
                path: 'device-management/devices',
                children: [
                    { element: <DevicesDetailsPage />, index: true },
                    { path: ':id/detail', element: <DevicesDetailsPage1 /> },
                    { path: 'edit', element: <DevicesEditPage /> },
                    { path: 'list', element: <DevicesListPage /> },
                    { path: ':id/detail', element: <DevicesDetailsPage /> },
                    { path: 'bulk-edit', element: <DevicesBulkEditPage /> },
                    { path: 'create', element: <DevicesDetailsCreatePage /> },
                    { path: 'newcreate', element: <DevicesDetailsNewCreatePage /> },
                ],
            },

            {
                path: 'device-management/os-updates',
                children: [
                    { element: <OSUpdatesDetailsPage />, index: true },
                    { path: 'list', element: <OSUpdatesListPage /> },
                    { path: 'create', element: <OSUpdatesCreatePage /> },
                    { path: 'edit', element: <OSUpdatesEditPage /> },
                ],
            },

            {
                path: 'device-security/profiles',
                children: [
                    // { element: <ProfilesDetailPage />, index: true },
                    { element: <ProfilesListPage />, index: true },
                    { path: 'list', element: <ProfilesListPage /> },
                    { path: 'create', element: <ProfilesCreatePage /> },
                    { path: 'newcreate', element: <ProfilesNewCreatePage /> },
                    { path: 'edit', element: <ProfilesEditPage /> },
                    { path: 'details', element: <ProfilesDetailPage /> },
                    { path: 'workprofile', element: <ProfilesWorkPage /> },
                ],
            },
            {
                path: 'device-security/policies',
                children: [
                    // { element: <ProfilesDetailPage />, index: true },
                    // { element: <ProfilesListPage />, index: true },

                    { path: 'create', element: <PoliciesCreatePage /> },
                    { path: 'list', element: <PoliciesListPage /> },
                    // { path: 'edit', element: <ProfilesEditPage /> },
                    // { path: 'details', element: <ProfilesDetailPage /> },
                    // { path: 'workprofile', element: <ProfilesWorkPage /> },
                ],
            },

            {
                path: 'device-management/enterprises-and-groups',
                children: [{ element: <EnterprisesAndGroupsPage />, index: true }],
            },

            {
                path: 'device-management/schedule-device-scan',
                children: [
                    { element: <ScheduleDeviceScanCreateSchedulePage />, index: true },
                    { path: 'scan-now', element: <ScheduleDeviceScanNowPage /> },
                ],
            },

            {
                path: 'application-management/app-repository',
                children: [
                    { element: <AppRepositoryListPage />, index: true },
                    { path: 'list', element: <AppRepositoryListPage /> },
                    { path: 'app-list', element: <AppList /> },
                    { path: 'detail', element: <AppRepositoryDetailsPage /> },
                    { path: 'distribute', element: <AppRepositoryDistributePage /> },
                    { path: ':id/distribute', element: <AppRepositoryDistributePage /> },
                    { path: ':appId/detail', element: <AppRepositoryDetailsPage /> },
                    { path: 'distribute-apps', element: <DistributeApps /> },
                ],
            },
            {
                path: 'application-management/app-stores/orgstore',
                children: [{ element: <OrgPrivateApps />, index: true }],
            },
            {
                path: 'application-management/app-stores/google',
                children: [
                    { element: <Appstorepublicapps />, index: true },
                    { path: 'publicapps', element: <Appstorepublicapps /> },
                    { path: 'privateapps', element: <Appstoreprivateapps /> },
                    { path: 'webapps', element: <Appstorewebapps /> },
                    { path: 'organize-apps', element: <OrganizeApps /> },
                ],
            },
            {
                path: 'content-manager',
                children: [{ element: <ContentManager />, index: true }],
            },
            {
                path: 'remote-control',
                children: [{ element: <RemoteControl />, index: true }],
            },
            {
                path:'windows-enrollment',
                children: [{ element: <AutoEnrollmentPage/>, index: true }],
            },

            // {
            //     path: 'application-management/app-repository',
            //     children: [
            //         { element: <AppRepositoryListPage />, index: true },
            //         { path: 'list', element: <AppRepositoryListPage /> },
            //         { path: 'app-list', element: <AppList /> },

            //         { path: 'detail', element: <AppRepositoryDetailsPage /> },
            //         { path: 'distribute', element: <AppRepositoryDistributePage /> },
            //         { path: ':id/distribute', element: <AppRepositoryDistributePage /> },
            //         { path: 'detail', element: <AppRepositoryDetailsPage /> },
            //         { path: ':appId/detail', element: <AppRepositoryDetailsPage /> },
            //         { path: 'create', element: <AppRepositoryCreatePage /> },
            //         { path: 'create-test', element: <AppRepositoryCreatePagetest /> },
            //         { path: 'publicapps', element: <Appstorepublicapps /> },
            //         { path: 'privateapps', element: <Appstoreprivateapps /> },
            //         { path: 'webapps', element: <Appstorewebapps /> },

            //         { path: 'organize-apps', element: <OrganizeApps /> },
            //         { path: 'web-apps', element: <WebApps /> },
            //         { path: 'distribute-apps', element: <DistributeApps /> },

            //         { path: 'app-security', element: <AppSecurity /> },
            //         { path: 'edit', element: <AppRepositoryEditPage /> },
            //         { path: 'test', element: <AppRepositorytest /> },
            //     ],
            // },

            {
                path: 'application-management/app-update-policy',
                children: [
                    { element: <AppUpdatePolicyDetailsPage />, index: true },
                    { path: 'edit', element: <AppUpdatePolicyEditPage /> },
                    { path: 'list', element: <AppUpdatePolicyListPage /> },
                    { path: 'create', element: <AppUpdatePolicyCreate /> },
                ],
            },

            {
                path: 'location-management/geo-fence-policy',
                children: [
                    { element: <GeoFencePolicyDetailsPage />, index: true },
                    { path: 'list', element: <GeoFencePolicyListPage /> },
                    { path: 'create', element: <GeoFencePolicyCreatePage /> },
                    { path: 'geomaps', element: <GeoFenceMaps /> },
                    { path: 'edit', element: <GeoFencePolicyEditPage /> },
                ],
            },

            {
                path: 'location-management/geo-fence-repository',
                children: [
                    { element: <GeoFenceRepositoryDetailsPage />, index: true },
                    { path: 'list', element: <GeoFenceRepositoryListPage /> },
                    { path: 'create', element: <GeoFenceRepositoryCreatePage /> },
                    { path: 'edit', element: <GeoFenceRepositoryEditPage /> },
                ],
            },

            {
                path: 'location-management/tracking',
                children: [
                    { element: <LocationHistory />, index: true },
                    { path: 'location-history', element: <LocationHistory />, index: true },
                    { path: 'live-tracking', element: <LiveTracking /> },
                    { path: 'route-maps', element: <RouteMapsBreadCrumbs /> },
                ],
            },

            {
                path: 'location-management/v2/geo-fencing',
                children: [
                    { element: <GeoFencingCreate />, index: true },
                    { path: 'create', element: <GeoFencingCreate /> },
                    { path: 'cards', element: <GeoFencingCards /> },
                    { path: 'logs', element: <GeoFenceLogs /> },
                    { path: 'apply', element: <GeoFenceApply /> },
                ],
            },

            {
                path: 'reports',
                children: [{ element: <ReportsPage />, index: true }],
            },

            {
                path: 'content-management/content',
                children: [
                    { element: <ContentDetailsPage />, index: true },
                    { path: 'list', element: <ContentListPage /> },
                    { path: 'create', element: <ContentCreatePage /> },
                    { path: 'edit', element: <ContentEditPage /> },
                ],
            },

            {
                path: 'tools',
                children: [
                    { element: <ToolsRemoteControlPage />, index: true },
                    { path: 'notification', element: <ToolsNotificationPage /> },
                ],
            },

            {
                path: 'product',
                children: [
                    { element: <ProductListPage />, index: true },
                    { path: 'list', element: <ProductListPage /> },
                    { path: ':id', element: <ProductDetailsPage /> },
                    { path: 'new', element: <ProductCreatePage /> },
                    { path: ':id/edit', element: <ProductEditPage /> },
                ],
            },
            {
                path: 'order',
                children: [
                    { element: <OrderListPage />, index: true },
                    { path: 'list', element: <OrderListPage /> },
                    { path: ':id', element: <OrderDetailsPage /> },
                ],
            },
            {
                path: 'invoice',
                children: [
                    { element: <InvoiceListPage />, index: true },
                    { path: 'list', element: <InvoiceListPage /> },
                    { path: ':id', element: <InvoiceDetailsPage /> },
                    { path: ':id/edit', element: <InvoiceEditPage /> },
                    { path: 'new', element: <InvoiceCreatePage /> },
                ],
            },
            {
                path: 'post',
                children: [
                    { element: <BlogPostsPage />, index: true },
                    { path: 'list', element: <BlogPostsPage /> },
                    { path: ':title', element: <BlogPostPage /> },
                    { path: ':title/edit', element: <BlogEditPostPage /> },
                    { path: 'new', element: <BlogNewPostPage /> },
                ],
            },
            {
                path: 'job',
                children: [
                    { element: <JobListPage />, index: true },
                    { path: 'list', element: <JobListPage /> },
                    { path: ':id', element: <JobDetailsPage /> },
                    { path: 'new', element: <JobCreatePage /> },
                    { path: ':id/edit', element: <JobEditPage /> },
                ],
            },
            {
                path: 'tour',
                children: [
                    { element: <TourListPage />, index: true },
                    { path: 'list', element: <TourListPage /> },
                    { path: ':id', element: <TourDetailsPage /> },
                    { path: 'new', element: <TourCreatePage /> },
                    { path: ':id/edit', element: <TourEditPage /> },
                ],
            },
            { path: 'file-manager', element: <FileManagerPage /> },
            { path: 'mail', element: <MailPage /> },
            { path: 'chat', element: <ChatPage /> },
            { path: 'calendar', element: <CalendarPage /> },
            { path: 'kanban', element: <KanbanPage /> },
            { path: 'permission', element: <PermissionDeniedPage /> },
            { path: 'blank', element: <BlankPage /> },
        ],
    },
];
